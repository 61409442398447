<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm" autocomplete="off">
      <b-row>
        <b-col cols="12 pt-3">
          <validation-provider name="Başlık" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Başlık">
              <b-form-input
                size="lg"
                class="rounded-0"
                ref="baslik"
                v-model="form.baslik"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Son Tüketim Tarihi">
            <flat-pickr
              v-model="date"
              class="form-control form-control-lg rounded-0 bg-white"
              :config="config"
              :events="config.events"
              @on-change="handlerOnChangeDate"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Kupon Adeti">
            <b-input-group>
              <b-form-input
                size="lg"
                type="number"
                class="rounded-0 text-right"
                v-model="form.kupon_adet"
                aria-describedby="input-1-live-feedback"
              />
              <b-input-group-prepend is-text> Adet </b-input-group-prepend>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="Kullanım Türü">
            <v-select
              v-model="form.kullanim_turu"
              :options="KullanimTuru"
              :reduce="(kul) => kul.value"
              label="title"
              :clearable="false"
              :disabled="!form._id"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Kupon Kodu">
            <b-input-group>
              <b-form-input size="lg" class="rounded-0 text-right" v-model="form.kod" aria-describedby="input-1-live-feedback" />
              <b-input-group-prepend>
                <b-button variant="primary" @click="handlerKodUret"> Kod Üret</b-button>
              </b-input-group-prepend>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6">
          <b-form-group label="indirim Türü">
            <v-select
              v-model="form.indirim_turu"
              :options="IndirimTuru"
              :reduce="(tur) => tur.value"
              label="title"
              :clearable="false"
              :disabled="!form._id"
              class="invoice-filter-select d-block select-size-lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="indirim Oranı">
            <b-input-group>
              <b-input-group-append is-text> İndirim ( {{ form.indirim_turu == 'yuzde' ? '%' : 'Fiyat' }} )</b-input-group-append>
              <b-form-input
                size="lg"
                type="number"
                class="rounded-0 text-right"
                v-model="form.indirim_tutari"
                aria-describedby="input-1-live-feedback"
              />
              <b-input-group-prepend is-text> {{ form.indirim_turu == 'yuzde' ? '%' : form.kur_turu }} </b-input-group-prepend>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="d-flex justify-content-between align-items-center py-3">
            <label for="statu" class="h6">Kullanım Durumu</label>
            <b-form-checkbox :checked="form.statu" v-model="form.statu" switch size="lg" />
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="6" lg="6">
          <div class="d-flex">
            <b-button squared block type="submit" size="lg" variant="primary" class="mr-2">
              <i class="fad fa-save pr-2"></i>
              <span class="align-middle">{{ form.k_no == null ? 'EKLE' : 'GÜNCELLE' }}</span>
            </b-button>
            <b-button squared size="lg" variant="danger">
              <i class="fad fa-trash"></i>
            </b-button>
          </div>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import store from '@/store';
import moment from 'moment';
import vSelect from 'vue-select';
import 'flatpickr/dist/flatpickr.css';
import flatPickr from 'vue-flatpickr-component';
import { Turkish } from 'flatpickr/dist/l10n/tr.js';
import { useToast } from 'vue-toastification/composition';
import { defineComponent, ref, computed, toRefs, watch } from '@vue/composition-api';
export default defineComponent({
  components: {
    vSelect,
    flatPickr,
  },
  props: {
    updateData: {
      type: Object,
      required: false,
    },
  },
  setup(props, context) {
    const expo = {};
    const toast = useToast();
    expo.firmaKNo = computed(() => store.getters.getUserData.firma_k_no);
    expo.diller = computed(() => store.getters.getDilAyarlari.diller);
    expo.defaultDil = ref(store.getters.getDilAyarlari.varsayilan);
    expo.kurTurleri = computed(() => store.getters.getKurAyarlari);
    const { updateData } = toRefs(props);

    expo.date = ref(moment().format('DD.MM.YYYY'));
    expo.config = ref({
      wrap: true,
      events: ['onChange', 'onClose', 'onDayCreate', 'onStartChange'],
      inline: false,
      mode: 'single',
      // minDate: 'today',
      dateFormat: 'd.m.Y',
      locale: Turkish, // locale for this instance only
    });

    expo.KullanimTuru = ref([
      {
        value: 'tekli',
        title: 'Tek Kullanım',
      },
      {
        value: 'coklu',
        title: 'Çoklu Kullanım',
      },
    ]);

    expo.IndirimTuru = ref([
      {
        value: 'yuzde',
        title: 'Yüzde',
      },
      {
        value: 'fiyat',
        title: 'Fiyat',
      },
    ]);

    expo.form = ref({
      k_no: null,
      baslik: 'Yeni Kupon',
      son_k_tarihi: moment().format('YYYY-MM-DD'),
      kupon_adet: 0,
      kod: null,
      kullanim_turu: 'tekli',
      indirim_turu: 'yuzde',
      indirim_tutari: 0,
      kur_turu: 'TRY',
      statu: true,
    });

    expo.handlerKodUret = () => {
      const str_code = Buffer.from(Math.random().toString()).toString('base64').substr(10, 10);
      expo.form.value.kod = str_code;
    };

    expo.handlerOnChangeDate = (event) => {
      if (event) expo.form.value.son_k_tarihi = moment(String(event)).format('YYYY-MM-DD');
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    expo.onSubmit = () => {
      context.emit('show', true);
      store
        .dispatch('kuponGuncelle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Güncelleme Başarılı', { position: 'bottom-left' });
            context.emit('show', false);
            context.emit('updateRefresh', res.data.data);
          }
        })
        .catch((err) => {
          if (err.success === false) {
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    watch(
      updateData,
      (val) => {
        if (Object.keys(val).length) {
          expo.form.value = { ...val };
          expo.date.value = moment(val.son_k_tarihi).format('DD.MM.YYYY');
        } else {
          expo.resetForm();
        }
      },
      { immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
