<template>
  <BListCard
    :searchActive="true"
    :newAddActive="true"
    :totalActive="true"
    :show="show"
    :title="'Kupon İşlemleri'"
    :total="rows.length"
    :type="type"
    @newAdd="handlerNewItem"
    @searchInput="search = $event"
    @handlerClose="type = $event"
  >
    <template v-slot:leftBody>
      <b-alert variant="warning" show class="text-center m-3" v-if="!rows.length">
        <h4 class="alert-heading">Bilgi!</h4>
        <p>Henüz Kayıt Bulunmamaktadır.</p>
      </b-alert>

      <draggable v-else v-model="rows" tag="ul" class="list-group" handle=".draggable-task-handle" @end="handlerSiraChange">
        <KuponItem v-for="(item, index) in rows" :key="index" :item="item" @handlerUpdate="handlerUpdate($event)" />
      </draggable>
    </template>
    <template v-slot:rightBody>
      <div class="p-3">
        <b-alert variant="info" show class="text-center" v-if="!type">
          <h4 class="alert-heading">Bilgi!</h4>
          <p>Lütfen Yeni Kayıt Ekleme/Düzenlemek İçin İşlem Seçimi Yapınız.</p>
        </b-alert>
        <KuponEkle
          v-else
          :updateData="updateData"
          @show="show = $event"
          @updateRefresh="handlerUpdatedData"
          @handlerClose="type = $event"
        />
      </div>
    </template>
  </BListCard>
</template>

<script>
import BListCard from '@/components/cards/BListCard.vue';
import { ref, watch, defineComponent, computed } from '@vue/composition-api';
import store from '@/store';
import KuponEkle from './component/KuponEkle.vue';
import KuponItem from './component/KuponItem.vue';
import { useToast } from 'vue-toastification/composition';
import draggable from 'vuedraggable';
import moment from 'moment';
export default defineComponent({
  components: {
    draggable,
    BListCard,
    KuponEkle,
    KuponItem,
  },
  setup() {
    const expo = {};
    const toast = useToast();
    expo.kurTurleri = computed(() => store.getters.getKurAyarlari);
    expo.search = ref(null);
    expo.type = ref(false);
    expo.show = ref(false);
    expo.updateData = ref({});
    expo.rows = ref([]);
    expo.form = ref({
      k_no: null,
      baslik: 'Yeni Kupon',
      kupon_adet: 1,
      son_k_tarihi: moment().format('YYYY-MM-DD'),
      kod: Buffer.from(Math.random().toString()).toString('base64').substr(10, 10),
      kullanim_turu: 'tekli',
      indirim_turu: 'yuzde',
      indirim_tutari: 0,
      kur_turu: 'TRY',
      statu: true,
    });

    const handlerGetData = async () => {
      expo.show.value = true;
      await store.dispatch('kuponListele');
      await store.dispatch('kurAyarlariGetir');
      expo.rows.value = store.getters.getKuponlar;
      expo.show.value = false;
    };

    handlerGetData();

    expo.handlerNewItem = () => {
      expo.show.value = true;
      expo.form.value.kur_turu = expo.kurTurleri.value.varsayilan;

      store
        .dispatch('kuponEkle', expo.form.value)
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Ekleme Başarılı.', { position: 'bottom-left' });
            expo.updateData.value = res.data.data;
            expo.type.value = true;
            expo.show.value = false;
          }
        })
        .catch((err) => {
          if (err.success === false) {
            expo.show.value = false;
            toast.success(err.message, { position: 'bottom-left' });
          }
        });
    };

    expo.handlerUpdate = async (event) => {
      expo.show.value = true;
      expo.updateData.value = await event;
      expo.type.value = true;
      expo.show.value = false;
    };

    expo.handlerUpdatedData = () => {
      expo.rows.value = store.getters.getKuponlar;
    };

    expo.handlerSiraChange = () => {
      store
        .dispatch('kuponSiraGuncelle', { variantgrup: expo.rows.value })
        .then((res) => {
          if (res.data.success == true) {
            toast.success('Sıra güncelleme başarılı.', { position: 'bottom-left' });
          }
        })
        .catch((err) => {
          if (err.success == false) {
            toast.error(
              Object.keys(err.data.dup).length > 0 ? Object.keys(err.data.dup)[0] + ' ' + err.data.message : err.data.message,
              { position: 'bottom-left' }
            );
          }
        });
    };

    watch(expo.search, (val) => {
      if (val.length > 0) {
        const search = val.toLocaleLowerCase('TR').trim();
        let filteredData = store.getters.getKuponlar;
        filteredData = filteredData.filter((rows) => {
          return rows.icerik['tr'].baslik.toLocaleLowerCase('TR').includes(search);
        });
        expo.rows.value = filteredData;
      } else {
        expo.rows.value = store.getters.getKuponlar;
      }
    });

    return { ...expo };
  },
});
</script>

<style lang="scss"></style>
